import * as React from "react";

import DropDownSelector from "../../components/DropDownSelector";
import CreatableSelect from 'react-select/creatable';

/** Presentation */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import EditCard from "../../components/EditCard";

/** Context */
import { Context } from "../../contexts/Context";

/** Custom types*/
import { IUpdateCardProps } from "../../custom-types";

/** Constants */
import {
  NOTIFICATION_MESSAGE,
  EDIT_CARD_PROPS,
  UpdateProperty,
} from "../../utils/Consts";

/** Utils */
import helpers, {
  updateProfile,
  updateProperties,
  updateProperty,
  getDefaultOptions,
  getTodaysDateTimeString,
  saveNewTerms
} from "../../utils/Helper";

const Editinterests: React.FC<IUpdateCardProps> = ({
  header,
  props,
  setShow,
  onUpdate,
}: IUpdateCardProps) => {
  const { state } = React.useContext(Context);
  const { showSuccess, showError, currentUser } = state;
  const [loading, setLoading] = React.useState(false);
  const [invalidTerm, setInvalid] = React.useState(state.isInvalidTerm ? state.isInvalidTerm : false);
  const [memberships, setMemberships] = React.useState(
    props[EDIT_CARD_PROPS.MembershipCard.membership]
      ? props
      : { [EDIT_CARD_PROPS.MembershipCard.membership]: "" }
  );
  const [terms, setNewTerms] = React.useState("");

  const [termRequest, setTermRequest] = React.useState(false);


  const updateinterests = React.useCallback(async () => {
    if (!invalidTerm) {
      if (
        props[EDIT_CARD_PROPS.MembershipCard.membership] !==
        memberships[EDIT_CARD_PROPS.MembershipCard.membership]
      ) {
        try {
          setLoading(true);
          if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
            const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
              helpers.appweburl + UpdateProperty.single,
              updateProperty(
                currentUser.LoginName,
                EDIT_CARD_PROPS.LastUpdated,
                getTodaysDateTimeString()
              )
            );

            const membershipsUpdateResponse: SP.ResponseInfo = await updateProfile(
              helpers.appweburl + UpdateProperty.multiple,
              updateProperties(
                currentUser.LoginName,
                EDIT_CARD_PROPS.MembershipCard.membership,
                memberships[EDIT_CARD_PROPS.MembershipCard.membership].split("|")
              )
            );


            if (
              membershipsUpdateResponse.statusCode === 200 &&
              lastUpdatedResponse.statusCode === 200
            ) {
              state.updated = true;
              setLoading(false);
              setShow(false);
              onUpdate(memberships);
              showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
            } else {
              setLoading(false);
              setShow(false);
              setMemberships(props);
              showError(NOTIFICATION_MESSAGE.PreferenceError);
            }

            if (terms != "") {

              await saveNewTerms({ Title: currentUser.Email, IndustryBodyProfessionalMembersh: terms });
            }
          } else {
            onUpdate(memberships);
            setLoading(false);
            setShow(false);
            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          }
        } catch (error) {
          setLoading(false);
          setShow(false);
          setMemberships(props);
          showError(NOTIFICATION_MESSAGE.Error);
        }
      } else {
        setShow(false);
        if (terms != "") {
          const UpdateResponse: SP.ResponseInfo = await saveNewTerms({ Title: currentUser.Email, IndustryBodyProfessionalMembersh: terms });
          if (UpdateResponse.statusCode === 201) {
            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          }
        }
      }
    } else {
      setInvalid(true);
    }
  }, [memberships, terms]);

  const onChange = (e) => {
    invalidateTerm(e);
    e
      ? setMemberships({
        [EDIT_CARD_PROPS.MembershipCard.membership]: e
          .map((membership) => membership.value)
          .join("|"),
      })
      : setMemberships({ [EDIT_CARD_PROPS.MembershipCard.membership]: "" });
  };

  const handleTerm = (e) => {

    setNewTerms(e.map((term) => term.value).join("|"));
  };


  const invalidateTerm = (options) => {
    setInvalid(false);
    if (options) {
      options.forEach((o) => {
        if (o.color && o.color == '#DD1D21') {
          setInvalid(true);
        }
      });
    }
  }
  const main: JSX.Element = (
    <Container role="main">
      {loading && (
        <div className="p-5 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      <Form.Group className="mb-4">
        <DropDownSelector
          title="Industry Body and Professional Membership"
          defaultValue={
            memberships[EDIT_CARD_PROPS.MembershipCard.membership] &&
              memberships[EDIT_CARD_PROPS.MembershipCard.membership] !== ""
              ? getDefaultOptions(
                memberships[EDIT_CARD_PROPS.MembershipCard.membership].split(
                  "|"
                )
              )
              : ""
          }
          setProp={(e) => onChange(e)}
        />
        <div style={{ marginTop: "10px", fontSize: "14px" }}>
          Please delete highlighted entries, reselect the term from the dropdown list and save. If preferred term is unavailable, please <a className="addTermSetLink" title="Request new terms" href="#" onClick={(e) => setTermRequest(true)} >Request new terms</a> to be added to your profile. You will receive a confirmation email upon completion of your request.
        </div>

        <div style={{ marginTop: "5%", fontSize: "14px", }}>


          {termRequest &&
            <div>
              <CreatableSelect
                noOptionsMessage={() => null}
                placeholder="Please type your new term here..."
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Menu: () => null }}
                isMulti
                onChange={handleTerm}
                style={{ marginTop: "1%" }}

              />
               <Form.Text style={{ fontSize: "small" }}>
                Please press ENTER after completing each keyword.
              </Form.Text>

            </div>
          }

        </div>
      </Form.Group>
    </Container>
  );

  const cancelHandler = () => {
    setShow(false);
    setMemberships(props);
  };

  return (
    <EditCard
      header={
        props[EDIT_CARD_PROPS.MembershipCard.membership]
          ? `Edit ${header}`
          : `Add ${header}`
      }
      show
      loading={loading}
      onHide={cancelHandler}
      update={updateinterests}
      body={main}
      newTermRequest={termRequest}
    />
  );
};

export default Editinterests;
