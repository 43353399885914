import React from "react";

import { Img, SiteLogo } from "../components/Styles";
/** Context */
import { Context } from "../contexts/Context";
import Button from "react-bootstrap/Button";
import { Search, XCircleFill } from "react-bootstrap-icons";
import logo from "../assets/images/logo.png";
import helpers from "../utils/Helper";
import useCardHandler from "../utils/hooks/CardHandler";
import { DELEGATE } from "../utils/Consts";
import EditDelegate from "./Delegate/EditDelegate";
export const Header = (props) => {
    const { openTour } = props;    
    const { state } = React.useContext(Context);   
    const {config, userProps, showError } = state;
    const [expertiseFinder, setExpertiseFinder] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [showDelegate, setShowDelegate] = React.useState(false);
    const [delegate, setDelegate] = React.useState("");
    const objFeedbackEmail: string = "mailto:KM-Support@shell.com?subject=Expertise Finder Feedback" //config.length > 0 ? config.filter(obj => obj.Key === "FeedbackEmail")[0].Value : null; 
    //prod
    const objRefGuide: string = "https://eu001-sp.shell.com/:b:/r/sites/AAAAB0586/TaxonomyTerms/Expertise%20Finder_QRG_FAQ_v3.pdf?csf=1&web=1&e=2bRHwQ"; //config.length > 0 ? config.filter(obj => obj.Key === "ReferenceGuide")[0].Value : null;
    //acceptance
    //const objRefGuide: string = "https://eu023-sp.shell.com/:b:/r/sites/SPOAA1153/TaxonomyTerms/Expertise%20Finder_QRG_FAQ_v3.pdf?csf=1&web=1&e=ZHSU9V"; //config.length > 0 ? config.filter(obj => obj.Key === "ReferenceGuide")[0].Value : null;
    const { getCardProps } = useCardHandler(DELEGATE, userProps);
    React.useEffect(() => {
        const clearIcon: HTMLInputElement = document.querySelector(".clear-icon");
        const searchBar: HTMLInputElement = document.querySelector(".search");
        const searchIcon: HTMLInputElement = document.querySelector(".search-icon");
        onLoad();
        if (helpers.IsObj(config)) {
            setExpertiseFinder(config);
            setLoading(false);
        }
        searchBar.addEventListener("keyup", (event) => {
            clearIcon.style.visibility = "visible";
            if (searchBar.value) {
                if (event.key === "Enter") {
                    clearIcon.style.visibility = "hidden";
                    window.open(`${helpers.searchRedirectURL}queryString=${searchBar.value}`, "_blank");
                    searchBar.value = "";
                }

            } else if (!searchBar.value) {
                clearIcon.style.visibility = "hidden";
            }
        });

        clearIcon.addEventListener("click", () => {
            searchBar.value = "";
            clearIcon.style.visibility = "hidden";
        });

        searchIcon.addEventListener("click", () => {
            if (searchBar.value.trim() !== "") {
                window.open(
                    `${helpers.searchRedirectURL}queryString=${searchBar.value}`,
                    "_blank"
                );
                searchBar.value = "";
                clearIcon.style.visibility = "hidden";
            }
        });
    }, [userProps, expertiseFinder, config]);

    const onLoad = async () => {
    try {
      if (helpers.IsObj(userProps)) {
        const props = await getCardProps();         
        setDelegate(props['Assistant'].split('|')[2]); 
        setLoading(false);
      }
    } catch (err) {
      showError(err.message);
      setLoading(false);
    }
  };
    const handleUpdate = React.useCallback(
    (val) => {
      setDelegate(val);
    },
    [delegate]
  );
    return (
        <div>
            {showDelegate && (
               <EditDelegate
                props={delegate}
                show={showDelegate}
                setShow={setShowDelegate}
                onUpdate={handleUpdate}
               />
            )}         
        <div className="topBanner">
            <div className="headerCol1">
                <div id="appTitle" style={{float:"left"}}>
                    <a href="https://eu001-sp.shell.com/sites/AAAAB1732/Pages/Expertise-Finder.aspx" target="_bank" >
                        <SiteLogo>
                            <Img src={logo} style={{paddingBottom:"5px"}} alt="Logo" />                            
                            <h1 style={{fontSize:"26px", fontFamily:"ShellBook", color:"#58595B"}}>Expertise Finder</h1>
                        </SiteLogo>
                    </a>
                </div>
            </div>
            <div className="headerCol2">
                <div id="searchBoxTour">                
                    <input placeholder="Search Profiles" type="text" className="search" />
                    <XCircleFill className="clear-icon" />
                    <Search className="search-icon" />
                </div>
            </div>
            <div className="headerCol3">
               {props.edit &&
                    <div className="feedbackIcon"> 
                        <a onClick={openTour} className="feedbackLink" >Onboarding</a>
                    </div>
                }                
                <div className="feedbackIcon">
                    <a target="_blank" className="feedbackLink" title="Quick Reference Guide" href={objRefGuide}>
                    Reference Guide & FAQ
                    </a>                   
                </div>
                {props.edit &&
                    <div className="feedbackIcon">
                        <a title="Feedback" href={objFeedbackEmail} className="feedbackLink">
                            Support
                        </a>
                    </div> 
                }
            </div>
        </div>
    </div>
    );
}

export default Header;
