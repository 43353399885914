import * as React from "react";
import CreatableSelect from 'react-select/creatable';

/** Presentation */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import AsyncSelect from "react-select/async";
import EditCard from "../../components/EditCard";

/** Context */
import { Context } from "../../contexts/Context";

import useTaxonomyHandler from "../../utils/hooks/TaxonomyHandler";

/** Constants */
import {
  NOTIFICATION_MESSAGE,
  EDIT_CARD_PROPS,
  UpdateProperty,
} from "../../utils/Consts";

/** Utils */
import helpers, {
  updateProfile,
  updateProperty,
  getDefaultOptions,
  getTodaysDateTimeString,
  saveNewTerms
} from "../../utils/Helper";

const EditEducation = ({
  header,
  index,
  props,
  show,
  setShow,
  onUpdate,
  onDelete
}) => {
  const { id } = props;
  const { state } = React.useContext(Context);
  const { showSuccess, showError, currentUser } = state;
  const [loading, setLoading] = React.useState(false);
  const [education, setEducation] = React.useState(props);
  const { getSchools, getCountry, getDegree } = useTaxonomyHandler();
  const [folksonomy, setFolksonomy] = React.useState<string[]>([]);
  const [folksonomyDegree, setFolksonomyDegree] = React.useState<string[]>([]);
  const [degreeValue, setDegreeValue] = React.useState(education.degree);
  const [schoolValue, setSchoolValue] = React.useState(education.school);
  const [countryValue, setCountryValue] = React.useState(education.country);
  const [countries, loadCountries] = React.useState<string[]>([]);
  const [termsDegree, setNewTermsDegree] = React.useState("");
  const [termsSchool, setNewTermsSchool] = React.useState("");

  const [termRequest, setTermRequest] = React.useState(false);
  React.useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    try {
      const folksonomyData = await getSchools();
      const folksonomyDataDegree = await getDegree();
      const countries = await getCountry();
      loadCountries(Object.keys(countries).sort())
      setFolksonomy(folksonomyData);
      setFolksonomyDegree(folksonomyDataDegree);
    } catch (err) {
      console.log({ err });
    }
  };

  const LoadingMessage = (props) => {
    return (
      <div
        {...props.innerProps}
        style={props.getStyles("loadingMessage", props)}
      >
        {props.selectProps.inputValue.length >= 2
          ? "Loading education..."
          : `Please enter ${2 - props.selectProps.inputValue.length
          } more character(s)`}
      </div>
    );
  };

  const updateEducation = React.useCallback(async () => {
    if (degreeValue || schoolValue || countryValue) {
      try {
        setLoading(true);
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
              new Date().toLocaleString()
            )
          );

          const updateDegreeResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.EducationCard.degree.concat(id),
              degreeValue ? degreeValue : ""
            )
          );

          const updateSchoolResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.EducationCard.school.concat(id),
              schoolValue ? schoolValue : ""
            )
          );

          const updateCountryResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.EducationCard.country.concat(id),
              countryValue ? countryValue : ""
            )
          );


          if (
            updateDegreeResponse.statusCode === 200 &&
            updateSchoolResponse.statusCode === 200 &&
            updateCountryResponse.statusCode === 200 &&
            lastUpdatedResponse.statusCode === 200
          ) {
            setLoading(false);
            setShow(false);
            onUpdate({
              id: id,
              degree: degreeValue ? degreeValue : "",
              school: schoolValue ? schoolValue : "",
              country: countryValue ? countryValue : ""
            });
            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          } else {
            setLoading(false);
            setShow(false);
            showError(NOTIFICATION_MESSAGE.PreferenceError);
          }
          if (termsDegree != "") {
            await saveNewTerms({ Title: currentUser.Email, Degree: termsDegree });

          }

          if (termsSchool != "") {
            await saveNewTerms({ Title: currentUser.Email, School: termsSchool });

          }
        } else {
          setLoading(false);
          setShow(false);
          onUpdate({
            id: id,
            degree: degreeValue ? degreeValue : "",
            school: schoolValue ? schoolValue : "",
            country: countryValue ? countryValue : ""
          });
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      } catch (error) {
        setLoading(false);
        setShow(false);
        showError(NOTIFICATION_MESSAGE.Error);
      }
    } else {
      setShow(false);

      if (termsDegree != "") {
        const UpdateDegreeResponse: SP.ResponseInfo = await saveNewTerms({ Title: currentUser.Email, Degree: termsDegree });
        if (UpdateDegreeResponse.statusCode === 201) {
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      }

      if (termsSchool != "") {
        const UpdateSchoolResponse: SP.ResponseInfo = await saveNewTerms({ Title: currentUser.Email, School: termsSchool });
        if (UpdateSchoolResponse.statusCode === 201) {
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      }
    }
  }, [
    degreeValue,
    schoolValue,
    countryValue, termsDegree, termsSchool
  ]);
  const deleteEducation = React.useCallback(async () => {
    if (degreeValue || schoolValue || countryValue) {
      try {
        setLoading(true);
        if (window.location.href.toLowerCase().indexOf("localhost") === -1) {
          const lastUpdatedResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.LastUpdated,
              getTodaysDateTimeString()
            )
          );

          const updateDegreeResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.EducationCard.degree.concat(id),
              ""
            )
          );

          const updateSchoolResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.EducationCard.school.concat(id),
              ""
            )
          );

          const updateCountryResponse: SP.ResponseInfo = await updateProfile(
            helpers.appweburl + UpdateProperty.single,
            updateProperty(
              currentUser.LoginName,
              EDIT_CARD_PROPS.EducationCard.country.concat(id),
              ""
            )
          );
          if (
            updateDegreeResponse.statusCode === 200 &&
            updateSchoolResponse.statusCode === 200 &&
            updateCountryResponse.statusCode === 200 &&
            lastUpdatedResponse.statusCode === 200
          ) {
            state.updated = true;
            setLoading(false);
            setShow(false);
            setDegreeValue("");
            setSchoolValue("");
            setCountryValue("");
            onDelete({
              id: id,
              degree: "",
              school: "",
              country: ""
            });

            showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
          } else {
            setLoading(false);
            setShow(false);
            showError(NOTIFICATION_MESSAGE.PreferenceError);
          }
        } else {
          setLoading(false);
          setShow(false);
          setDegreeValue("");
          setSchoolValue("");
          setCountryValue("");
          onDelete({
            id: id,
            degree: "",
            school: "",
            country: ""
          });
          showSuccess(NOTIFICATION_MESSAGE.PreferenceSuccess);
        }
      } catch (error) {
        setLoading(false);
        setShow(false);
        showError(NOTIFICATION_MESSAGE.Error);
      }
    } else setShow(false);
  }, [
    degreeValue,
    schoolValue,
    countryValue
  ]);
  const folksonomyOptions = (inputValue: string) =>
    getDefaultOptions(
      folksonomy
        .filter((i) => i.toLowerCase().includes(inputValue.toLowerCase()))
        .slice(0, 30).map((j) => j.slice(0, -1))
    );

  const folksonomyOptionsDegree = (inputValue: string) =>
    getDefaultOptions(
      folksonomyDegree
        .filter((i) => i.toLowerCase().includes(inputValue.toLowerCase()))
        .slice(0, 30).map((j) => j.slice(0, -1))
    );

  const promiseOptionsDegree = (inputValue) => {
    if (inputValue.length >= 2) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(folksonomyOptionsDegree(inputValue));
        }, 1000);
      });
    }
  };

  const CountryOptions = (inputValue: string) =>
    getDefaultOptions(
      countries
        .filter((i) => i.toLowerCase().includes(inputValue.toLowerCase()))
        .slice(0, 30)
    );

  const promiseOptions = (inputValue) => {
    if (inputValue.length >= 2) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(folksonomyOptions(inputValue));
        }, 1000);
      });
    }
  };
  const promiseCountryOptions = (inputValue) => {
    if (inputValue.length >= 2) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(CountryOptions(inputValue));
        }, 1000);
      });
    }
  };

  const handleTermDegree = (e) => {

    setNewTermsDegree(e.map((term) => term.value).join("|"));
  };
  const handleTermSchool = (e) => {

    setNewTermsSchool(e.map((term) => term.value).join("|"));
  };


  const main: JSX.Element = !loading ? (
    <Container role="main">
      <Form.Group className="mb-4" controlId="Education">
        <Form.Label className="textSmall" >
          Degree
        </Form.Label>
        <AsyncSelect
          aria-label="Degree"
          autoFocus
          noOptionsMessage={() => 'No options'}
          components={{ LoadingMessage }}
          onChange={(e) => setDegreeValue(e.value)}
          placeholder="Search Degree.."
          defaultValue={getDefaultOptions([education.degree])}
          cacheOptions
          folksonomyOptionsDegree
          loadOptions={promiseOptionsDegree}
        />
        <Form.Text>
          <b>Search Tip:</b> Use the field of study to narrow your options e.g.
          if searching for a Bachelor of Science degree in Economics,
          type Economics to narrow down your options in the picklist.
        </Form.Text>
        <Form.Label className="textSmall" >
          School
        </Form.Label>

        <AsyncSelect
          aria-label="School"
          autoFocus
          noOptionsMessage={() => 'No options'}
          components={{ LoadingMessage }}
          onChange={(e) => setSchoolValue(e.value)}
          placeholder="Search School.."
          defaultValue={getDefaultOptions([education.school])}
          cacheOptions
          folksonomyOptions
          loadOptions={promiseOptions}
        />
        <Form.Text>
          <b>Search Tip:</b> Use the unique name of School to narrow your options e.g.
          Tilburg or Harvard. Avoid starting your search with University, College or School.
        </Form.Text>
        <Form.Label className="textSmall" >
          Country
        </Form.Label>

        <AsyncSelect
          aria-label="Country"
          autoFocus
          noOptionsMessage={() => 'No options'}
          components={{ LoadingMessage }}
          onChange={(e) => setCountryValue(e.value)}
          placeholder="Search Country.."
          defaultValue={getDefaultOptions([education.country])}
          cacheOptions
          CountryOptions
          loadOptions={promiseCountryOptions}
        />
        <Form.Text>
          <b>Search Tip:</b> Search the Country location of the listed school.
        </Form.Text>
        <div style={{ marginTop: "10px", fontSize: "14px" }}>
          Please delete highlighted entries, reselect the term from the dropdown list and save. If preferred term is unavailable, please <a className="addTermSetLink" title="Request new terms" href="#" onClick={(e) => setTermRequest(true)} >Request new terms</a> to be added to your profile. You will receive a confirmation email upon completion of your request..
        </div>

        <div style={{ marginTop: "5%", fontSize: "14px", }}>


          {termRequest &&
            <div>

              <br />
              <Form.Label>
                Degree
              </Form.Label>
              <CreatableSelect
                noOptionsMessage={() => null}
                placeholder="Please type your new term here..."
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Menu: () => null }}
                isMulti
                onChange={handleTermDegree}
                style={{ marginTop: "1%" }}
              />
              <Form.Text style={{ fontSize: "small" }}>
                Please press ENTER after completing each keyword.
              </Form.Text>
              <br />
              <Form.Label>
                School
              </Form.Label>
              <CreatableSelect
                noOptionsMessage={() => null}
                placeholder="Please type your new term here..."
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Menu: () => null }}
                isMulti
                onChange={handleTermSchool}
                style={{ marginTop: "1%" }}
              />
              <Form.Text style={{ fontSize: "small" }}>
                Please press ENTER after completing each keyword.
              </Form.Text>

            </div>
          }

        </div>
      </Form.Group>
    </Container>
  ) : (
    <div className="p-5 text-center">
      <Spinner animation="border" variant="info" />
    </div>
  );

  const cancelHandler = () => {
    setShow(false);
    setDegreeValue(props.degree);
    setSchoolValue(props.school);
    setCountryValue(props.country);
    setEducation(props);
  };


  return (
    <EditCard
      header={`Edit ${header} ${index + 1}`}
      show
      loading={loading}
      onHide={cancelHandler}
      update={updateEducation}
      onDelete={deleteEducation}
      showDelete={true}
      body={main}
      showLarge={true}
      newTermRequest={termRequest}
    />
  );
};
export default EditEducation;







